/* eslint-disable camelcase */
import React, { FC, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import {
  BreadCrumbs,
  PageLayout,
  SocialShare,
  PageHeading,
  DieteticsService,
  RenderVideoAndImage,
  DietitianBookingsModal,
  FAQ,
  EnquireFormModal,
} from 'components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import RightArrowIcon from 'assets/svg/arrow-right.svg';
import ChatIcon from 'assets/svg/chat.svg';

const Dietition: FC = ({ data }: any) => {
  const {
    title,
    description,
    content,
    image,
    DietitianTab,
    dietetics_services,
    specialistAreaContent,
    seo,
    faq,
    ctaLink,
  } = data.strapiDietitian;
  const [DietitianModalOpen, setDietitianModalOpen] = useState(false);
  const handleDietitianModalOpen = () => {
    setDietitianModalOpen(true);
  };
  const dietitionImage =
    image && image.localFile
      ? getImage(image.localFile.childImageSharp?.gatsbyImageData)
      : null;
  const { ServiceVideoImage, banner } = data.ServiceImageandVideo;
  const VideoGallery: any = [];
  useEffect(() => {
    VideoGallery.push(ServiceVideoImage.video?.localFile.publicURL);
  }, [VideoGallery]);
  const booklink = data.strapiGlobal.dietitianLink;
  const breadCrumb = [
    { title: 'Home', slug: '/' },
    {
      title: 'Nutrition',
      slug: `/${data.strapiServicesPage.slug}/nutrition`,
    },
    {
      title: `${data.strapiDietitianPage.title}`,
      slug: `/${data.strapiServicesPage.slug}/nutrition/${data.strapiDietitianPage.slug}`,
    },
  ];
  const [dietitianEnquiryOpen, setDietitianEnquiryOpen] = useState(false);

  const [Tab, selectTab] = useState(0);
  return (
    <PageLayout
      title={title}
      isMainBannerNav={false}
      metaTitle={seo?.metaTitle ?? title}
      description={seo?.metaDescription}
      image={seo?.shareImage?.localFile?.publicURL}
      bannerTitle={banner?.title}
      bannerTagline={banner?.tagline}
      bannerImage={banner?.image}
      bannerButton={banner?.ctaButton}
      bannerPlaceholder={banner?.postcodePlaceholder}
      dealTitle="Nutrition Page"
    >
      <div className="container">
        <section className="dietition">
          <BreadCrumbs breadCrumb={breadCrumb} />
          <div className="dietition_heading">
            <h1>{title}</h1>

            <h3 className="dietition_consulting"> Consulting Dietitian </h3>
          </div>
          <div className="dietition_wrapper">
            <div className="dietition_image">
              <GatsbyImage image={dietitionImage} alt={title} />
              <div className="dietition_shares">
                <SocialShare
                  title={title}
                  image={image?.localFile?.publicURL}
                />
              </div>
              <div className="dietition_tabs">
                {DietitianTab.length > 0 ? (
                  <>
                    <div className="dietition_tab">
                      <ul>
                        {DietitianTab.map((item: any, index: number) => {
                          return (
                            <li
                              className={`dietition_tab-title ${
                                Tab === index ? 'active' : ''
                              }`}
                              key={item.title}
                            >
                              <button
                                type="button"
                                onClick={() => selectTab(index)}
                              >
                                <span>
                                  <ReactMarkdown className="md-html">
                                    {item.title}
                                  </ReactMarkdown>
                                </span>
                              </button>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="dietition_tab-content">
                      {DietitianTab.map((items: any, index: number) => {
                        return Tab === index ? (
                          <div
                            className="dietition_tab-content-description"
                            key={items.description}
                          >
                            <ReactMarkdown className="md-html">
                              {items.description}
                            </ReactMarkdown>
                          </div>
                        ) : (
                          ''
                        );
                      })}
                    </div>
                  </>
                ) : null}
              </div>
            </div>

            <div className="dietition_info">
              <ReactMarkdown className="dietition_summary">
                {description}
              </ReactMarkdown>
              <div className="dietition_summary_description">
                <ReactMarkdown className="md-html">{content}</ReactMarkdown>
              </div>
              <div className="btn-link-wrapper">
                <button
                  className="btn-diet-book"
                  type="button"
                  onClick={handleDietitianModalOpen}
                >
                  <span>
                    {ctaLink?.bookDietitianTitle
                      ? ctaLink.bookDietitianTitle
                      : booklink.dietitianAppointmentButtonText}
                  </span>
                </button>

                <DietitianBookingsModal
                  DietitianModalOpen={DietitianModalOpen}
                  setDietitianModalOpen={setDietitianModalOpen}
                  link={ctaLink.bookDietitianLink}
                />
                <EnquireFormModal
                  isEnquireModalOpen={dietitianEnquiryOpen}
                  setisEnquireModalOpen={setDietitianEnquiryOpen}
                  contactFormModalTitle={
                    ctaLink?.popupTitle ?? `Enquire now - ${title}`
                  }
                  optionalMessage
                  dietitian={title}
                  dealTitle={title}
                />
                {ctaLink.chatWithUsLink && (
                  <button
                    type="button"
                    className="chat-button"
                    onClick={() => {
                      setDietitianEnquiryOpen(true);
                    }}
                  >
                    <ChatIcon />
                    <span>{ctaLink.chatWithUsTitle}</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </section>
        <section>
          <PageHeading
            title={specialistAreaContent.title}
            subtitle={specialistAreaContent.tagline}
            description={specialistAreaContent.content}
          >
            <div className="dietition_specialist_link">
              <button type="button" onClick={handleDietitianModalOpen}>
                <div className="dietition_specialist_content">
                  <div className="dietition_specialist_content_item">
                    <span>Book Me </span>
                    <RightArrowIcon />
                  </div>
                  <div className="dietition_specialist_content_image">
                    <GatsbyImage image={dietitionImage} alt={title} />
                  </div>
                </div>
              </button>
            </div>
          </PageHeading>

          <div className="dietition_cards">
            <div className="dietetics-service">
              {dietetics_services.map((service: any) => {
                return (
                  <DieteticsService
                    service={service}
                    pathname={`/${data.strapiServicesPage.slug}/nutrition`}
                  />
                );
              })}
            </div>
          </div>
        </section>
      </div>
      {ServiceVideoImage && (
        <RenderVideoAndImage
          data={ServiceVideoImage}
          altText=""
          hasPlayButton
          hasBorder
          buttontype="text"
          buttonsize="large"
          videogallery={VideoGallery}
          showControls
          showModal
        />
      )}
      <div className="container">
        {faq && (
          <section className="home-page-faq-section">
            <PageHeading title={faq.title} subtitle={faq.tagline} />
            <FAQ Faqs={faq.faqs} />
          </section>
        )}
      </div>
    </PageLayout>
  );
};
export default Dietition;

export const query = graphql`
  query DietitionPage($slug: String) {
    strapiDietitian(slug: { eq: $slug }) {
      title
      content
      description
      slug
      ctaLink {
        bookDietitianLink
        bookDietitianTitle
        chatWithUsLink
        chatWithUsTitle
      }
      image {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 400, height: 400)
          }
        }
      }
      faq {
        tagline
        title
        faqs {
          answer
          title
        }
      }
      seo {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      DietitianTab {
        description
        id
        title
      }
      specialistAreaContent {
        title
        tagline
        content
      }
      dietetics_services {
        title
        slug
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    strapiServicesPage {
      title
      slug
    }
    ServiceImageandVideo: strapiServicePage(slug: { eq: "nutrition" }) {
      banner {
        ctaButton
        id
        postcodePlaceholder
        tagline
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      ServiceVideoImage {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        video {
          localFile {
            publicURL
          }
        }
      }
    }
    strapiGlobal {
      dietitianLink {
        bookDietitianButtonText
        bookDietitianLink
        dietitianAppointmentButtonText
        chatWithDietitianButtonText
        chatWithDietitianLink
        dietitianAppointmentLink
      }
    }
    strapiDietitianPage {
      title
      slug
      chatForm {
        chatFormTitle
      }
    }
  }
`;
